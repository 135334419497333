@use "../definitions/colors";
@use "../definitions/fonts";

@mixin styles {
    h1, h2 {
        @include fonts.saria();
        text-transform: lowercase;
        margin: 0;
        color: colors.get(primary);
        a {
            text-decoration: none;
        }
    }
}