@mixin styles {
    [data-hide="true"] {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}