:root {
  --padding: 0.5em 1em;
}

html {
  color: #232942;
  background-color: #fff;
  font-family: Times New Roman, serif;
  font-size: 1.7rem;
}

body {
  grid-gap: 1rem;
  padding: var(--padding);
  margin: 0;
  display: grid;
}

@media (min-width: 808px) {
  body {
    grid-template-columns: max-content auto;
  }
}

header {
  grid-column-start: 1;
}

a {
  color: inherit;
}

.container {
  max-width: 70ch;
}

.container p:first-child {
  margin-top: 0;
}

.container p:last-child {
  margin-bottom: 0;
}

@media (min-width: 808px) {
  .container {
    grid-row-start: 2;
    grid-column-start: 2;
  }
}

@font-face {
  font-family: Saira Condensed;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../saira-condensed-v6-latin-600.a952ab43.woff2") format("woff2");
}

h1, h2 {
  text-transform: lowercase;
  color: #03529c;
  margin: 0;
  font-family: Saira Condensed, sans-serif;
  font-weight: 600;
}

h1 a, h2 a {
  text-decoration: none;
}

.overview {
  margin-block: 0;
  padding-left: 0;
  list-style-type: none;
}

[data-hide="true"] {
  position: relative;
}

[data-hide="true"]:after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}

nav {
  grid-row-start: 2;
  font-size: .8rem;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav svg {
  height: .7em;
  width: auto;
  fill: currentColor;
  margin-right: .5em;
}

.home {
  color: #03529c;
}

main {
  flex-direction: column;
  display: flex;
}

/*# sourceMappingURL=main.css.map */
