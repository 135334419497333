@mixin styles {
    nav {
        grid-row-start: 2;
        font-size: 0.8rem;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
        svg {
            height: 0.7em;
            width: auto;
            fill: currentColor;
            margin-right: 0.5em;
        }
    }
}