@use "definitions/colors";
@use "definitions/fonts";
@use "components/headings";
@use "components/overview";
@use "components/protect-images";
@use "components/navigation";
@use "layout/home";

:root {
    --padding: 0.5em 1em;
}
html {
    font: {
        family: "Times New Roman", serif;
        size: 1.7rem;
    }
    background-color: colors.get(background);
    color: colors.get(type);
}

body {
    margin: 0;
    display: grid;
    grid-gap: 1rem;
    padding: var(--padding);
}
@media (min-width: 808px) {
    body {
        grid-template-columns: max-content auto;
    }
}
header {
    grid-column-start: 1;
}
a {
    color: inherit;
}
.container {
    max-width: 70ch;
    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 808px) {
    .container {
        grid-row-start: 2;
        grid-column-start: 2;
    }
}

// Defintions
@include fonts.styles;

// Components
@include headings.styles;
@include overview.styles;
@include protect-images.styles;
@include navigation.styles;

// Layouts
@include home.styles;