@use "../definitions/colors";

@mixin styles {
    .home {
        color: colors.get(primary);
    }
    main {
        display: flex;
        flex-direction: column;
    }
}