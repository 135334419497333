@mixin styles {
    /* saira-condensed-600 - latin */
    @font-face {
        font: {
            family: 'Saira Condensed';
            style: normal;
            weight: 600;
            display: swap;
        }
        src: url('/src/_fonts/saira-condensed-v6-latin-600.woff2') format('woff2')
    }
}

@mixin saria {
    font: {
        family: 'Saira Condensed', sans-serif;
        weight: 600;
    }
}